import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule, FirebaseApp } from 'angularfire2';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';

import { ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
    declarations: [
        AppComponent
    ],
    entryComponents: [

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        ReactiveFormsModule,
        IonicModule.forRoot(
            {
                backButtonText: '',
                mode: 'ios',
                backButtonIcon: 'arrow-back-outline',
            }
        ),
    ],
    providers: [
        {
            provide: LocationStrategy,
			useClass: PathLocationStrategy
        }
    ],
    bootstrap: [
        AppComponent
    ],
})
export class AppModule { }
