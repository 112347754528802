// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import 'zone.js/dist/zone-error';  // Included with Angular CLI.

export const environment = {
    production: false,
    firebase: {
        apiKey: "AIzaSyBFnP1Vl8nU9Li9gryQQD5ocdodUg86iNE",
        authDomain: "heineken-rifa.firebaseapp.com",
        projectId: "heineken-rifa",
        storageBucket: "heineken-rifa.appspot.com",
        messagingSenderId: "150816705555",
        appId: "1:150816705555:web:76101d738790e4763fc3e6"
    }
};

